<template>
	<div>
		<div style="width: 100%; height: 100%">
			<div
			  :style="`width: 100%; height: 100%; border: ${item.pxh}px solid #000`"
			></div>
		  </div>
	</div>
</template>

<script>
export default{
	name: 'Template10',
	props:{ 
		item: { require: false, default: ()=>{
			return {}
		}, type: Object },
	},
}

</script>

<style lang="less" scoped>

</style>