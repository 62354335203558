<template>
	<div>
		<vue-qr
			:margin="0"
			:text="qrcode || item.value || ''"
			:logoScale="0.2"
			:size="item.w"
		></vue-qr>
	</div>
</template>

<script>
import VueQr from "vue-qr";

export default{
	name: 'Template7',
	props:{ 
		qrcode:{require: false, default:'',type: String},
		item: { require: false, default: ()=>{
			return {}
		}, type: Object },
	},
	components: {
		VueQr
	}
}

</script>

<style lang="less" scoped>

</style>