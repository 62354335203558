<template>
	<div>
		<div style="width: 100%; height: 100%">
			<table border="1" style="width: 100%; height: auto">
			  <thead
				:style="`background-color: #ccc; box-sizing: border-box;font-family:${item.family} `"
			  >
				<td
				  v-for="(val, index) in item.columns"
				  :key="val.dataIndex"
				  :style="`width: ${val.width || 50}px;line-height: ${
					val.lineHeight || 20
				  }px;  text-align: ${val.align || 'center'};font-weight:${
					val.bold || 'initial'
				  };font-size:${val.fontSize || 18}px`"
				>
				  {{ val.title }}
				</td>
			  </thead>

			  <tr v-for="(itemdata, index) in item.datalist" :key="index">
				<td
				  v-for="(val, index) in item.columns"
				  :key="index"
				  :style="`text-align: ${val.textalign || 'center'};`"
				>
				  <div
					:style="`text-align: ${
					  val.textalign || 'center'
					};height:${
					  val.textlineHeight || 20
					}px;overflow: hidden;`"
				  >
					{{ itemdata[val.dataIndex] || "&nbsp;" }}
				  </div>
				</td>
			  </tr>
			</table>
		  </div>
	</div>
</template>

<script>
export default{
	name: 'Template4',
	props:{ 
		item: { require: false, default: ()=>{
			return {}
		}, type: Object },
	},
}

</script>

<style lang="less" scoped>

</style>