<template>
	<div>
		<div :style="`width: 100%;height: ${item.lineHeight}px;line-height: ${item.lineHeight}px;font-size: ${item.fontSize}px;font-weight:${item.bold}; flex-wrap: nowrap;`">
			<p>{{ item.Key }}</p>
		</div>
	</div>
</template>

<script>
export default{
	name: 'Template5',
	props:{ 
		item: { require: false, default: ()=>{
			return {}
		}, type: Object },
	},
}

</script>

<style lang="less" scoped>

</style>