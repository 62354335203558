import { render, staticRenderFns } from "./template9.vue?vue&type=template&id=40a8fe18&scoped=true&"
import script from "./template9.vue?vue&type=script&lang=js&"
export * from "./template9.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40a8fe18",
  null
  
)

export default component.exports