<template>
	<div>
		<div style="width: 100%; height: 100%">
			<p
			  :style="`background-color: #000; width: ${item.pxh}px; height: 100%`"
			></p>
		  </div>
	</div>
</template>

<script>
export default{
	name: 'Template9',
	props:{ 
		item: { require: false, default: ()=>{
			return {}
		}, type: Object },
	},
}

</script>

<style lang="less" scoped>

</style>