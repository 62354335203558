var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticStyle:{"width":"100%","height":"100%"}},[_c('table',{staticStyle:{"width":"100%","height":"auto"},attrs:{"border":"1"}},[_c('thead',{style:(`background-color: #ccc; box-sizing: border-box;font-family:${_vm.item.family} `)},_vm._l((_vm.item.columns),function(val,index){return _c('td',{key:val.dataIndex,style:(`width: ${val.width || 50}px;line-height: ${
				val.lineHeight || 20
			  }px;  text-align: ${val.align || 'center'};font-weight:${
				val.bold || 'initial'
			  };font-size:${val.fontSize || 18}px`)},[_vm._v(" "+_vm._s(val.title)+" ")])}),0),_vm._l((_vm.item.datalist),function(itemdata,index){return _c('tr',{key:index},_vm._l((_vm.item.columns),function(val,index){return _c('td',{key:index,style:(`text-align: ${val.textalign || 'center'};`)},[_c('div',{style:(`text-align: ${
				  val.textalign || 'center'
				};height:${
				  val.textlineHeight || 20
				}px;overflow: hidden;`)},[_vm._v(" "+_vm._s(itemdata[val.dataIndex] || " ")+" ")])])}),0)})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }