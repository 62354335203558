<template>
	<div>
		<div :style="`display: flex;width: 100%;height: ${item.lineHeight}px;line-height: ${item.lineHeight}px;font-size: ${item.fontSize}px;font-weight:${item.bold}; flex-wrap: nowrap;`">
			<div style="height: 100%" :ref="item.key + item.Id">
				{{ item.Key }}&nbsp;
			</div>
			<div :style="`flex:1;height: 100%;border-bottom: 1px solid #000;text-align: ${item.align};`">
				{{ item.value }}
			</div>
		</div>
	</div>
</template>

<script>
export default{
	name: 'Template6',
	props:{ 
		item: { require: false, default: ()=>{
			return {}
		}, type: Object },
	},
}

</script>

<style lang="less" scoped>

</style>